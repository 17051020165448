<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Stat from "./widget";
import RemittanceAnalytics from "./remittance-analytics";
import BusinessConditions from "./businessConditions";
import AccountBalances from "./accountBalances";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RemittanceAnalytics,
    AccountBalances,
    BusinessConditions
  },
  data() {
    return {
      title: "Teller Dashboard",
      items: [
        {
          text: "Smart Exchange"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <Stat />
        <div class="row">
          <div class="col-xl-6">
            <BusinessConditions />
          </div>
          <div class="col-xl-6">
            <AccountBalances />
          </div>

        </div>
        
      </div>
      <div class="col-xl-4">
        <RemittanceAnalytics />
        
      </div>
    </div>
  </Layout>
</template>